import React from "react";
import styled from "styled-components";
import { formatDate, formatMoney } from "../../Utils/helpers";

function Table({ data }) {
  return (
    <StyledTable>
      {data.map((el, i) => {
        return (
          <div className="row" key={i}>
            <p>{el.oppNum}</p>
            <p>{formatDate(el.date, true)}</p>
            <p>{el.name}</p>
            <p style={{ color: "#00BFEF" }}>{formatMoney(el.payAmount)}</p>
          </div>
        );
      })}
    </StyledTable>
  );
}

export default Table;

const StyledTable = styled.div`
  background: #f4f4f4;
  padding: 16px;
  border-radius: 16px;
  margin-top: 24px;
  max-height: 300px;
  overflow: auto;

  .row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    border-bottom: solid 1px #d1d1d1;
    padding-bottom: 12px;
    margin-bottom: 12px;
  }

  .row:last-of-type {
    border: none;
    padding: 0px;
    margin: 0px;
  }

  .row > p {
    flex: 1;
    min-width: 50%;
    padding: 0px;
    margin: 0px;
    font-size: 12px;
  }

  .row > p:nth-child(n + 3) {
    font-size: 14px;
  }

  .row > p:nth-child(even) {
    text-align: right;
  }
`;
