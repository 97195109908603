import React from "react";
import styled from "styled-components";

import XIcon from "../../assets/XIcon.svg";

import { formatMoney } from "../../Utils/helpers";

function PayChartLegend({ chartData, setShowLegend }) {
  return (
    <StyledLegend>
      <div className="card">
        <div className="header">
          <p>Pay Overview</p>
          <button onClick={() => setShowLegend(false)}>
            <img src={XIcon} alt="X to quit"></img>
          </button>
        </div>
        {chartData.map((el, i) => {
          if (!el.payAmount) return "";
          return (
            <div className="legend-item" key={i}>
              <p>{formatMoney(el.payAmount)}</p>
              <p className="label" style={{ color: el.color }}>
                {el.label}
              </p>
            </div>
          );
        })}
      </div>
    </StyledLegend>
  );
}

export default PayChartLegend;

const StyledLegend = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  z-index: 100;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);

  button {
    padding: 20;
  }

  p {
    font-family: Gotham;
    text-align: center;
    margin: 0;
  }

  .label {
    font-size: 12px;
  }

  .legend-item {
    flex: 1;
    min-width: 30%;
    justify-content: center;
    margin: 8px 0;
`;
