import axios from "axios";

export const getPayData = async (params, paygroup) => {
  const body = paygroup
    ? { pay_id: params.payid, paygroup }
    : { pay_id: params.payid };

  let url = `https://k1pav8l0y6.execute-api.us-east-1.amazonaws.com/latest/v2/dev/pay_entry`;
  // let url = `http://localhost:8000/v2/dev/pay_entry`;

  return await axios.post(
    // Add "_dev" to endpoint end during testing to see pay periods from father back.
    url,
    body
  );
};
