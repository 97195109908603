import React from "react";

function ComingSoon() {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "90vh",
      }}
    >
      <span
        style={{
          padding: "1em",
          boxShadow: "0 5px 5px rgba(0, 0, 0, 0.15)",
          fontSize: "2em",
          color: "white",
          background: "#336699",
          borderRadius: "10px",
          fontWeight: "bold",
        }}
      >
        Coming Soon!
      </span>
    </div>
  );
}

export default ComingSoon;
