import React from "react";
import LoaderStyle from "./styles";

const Loader = () => {
  return (
    <LoaderStyle>
      <div className="sk-folding-cube">
        <div className="sk-cube1 sk-cube"></div>
        <div className="sk-cube2 sk-cube"></div>
        <div className="sk-cube4 sk-cube"></div>
        <div className="sk-cube3 sk-cube"></div>
      </div>
    </LoaderStyle>
  );
};
export default Loader;
