import React, { useState } from "react";
import { buildTableData } from "../../Utils/helpers";
import styled from "styled-components";
import Table from "./Table";

function SitsAndSalesTable({ apiBody }) {
  const [isSits, setIsSits] = useState(true);
  return (
    <StyledSitsAndSales>
      <div>
        <button
          className={isSits ? "active" : ""}
          onClick={(e) => setIsSits(true)}
        >
          Sits
        </button>
        <button
          className={!isSits ? "active" : ""}
          onClick={(e) => setIsSits(false)}
        >
          Sales
        </button>
      </div>
      <div>
        {isSits &&
          (apiBody.sits.length > 0 ? (
            <Table data={buildTableData(apiBody.sits)} />
          ) : (
            <p style={{ color: "black" }}>No Sits</p>
          ))}

        {!isSits &&
          (apiBody.sales.length > 0 ? (
            <Table data={buildTableData(apiBody.sales)} />
          ) : (
            <p style={{ color: "black" }}>No Sales</p>
          ))}
      </div>
    </StyledSitsAndSales>
  );
}

export default SitsAndSalesTable;

const StyledSitsAndSales = styled.div`
  width: 100%;
  margin-bottom: 16px;

  button {
    width: 50%;
    color: #a9acaf;
    border-bottom: 3px solid #a9acaf;
    padding: 8px;
    font-size: 16px;
  }

  .active {
    color: #00bfef;
    border-color: #00bfef;
  }
`;
