import React from "react";
import { Select, MenuItem } from "@material-ui/core";

import CalendarIcon from "../../assets/CalendarIcon.svg";

import dayjs from "dayjs";
import styled from "styled-components";

function SelectPayGroup({ paygroups, currentPaygroup, selectChangeHandler }) {
  return (
    <StyledSelect>
      <div className="label-wrapper">
        <img src={CalendarIcon} alt={"calendar icon"} style={{ height: 16 }} />
        <label>Pay Period: </label>
      </div>
      <Select
        className="select"
        value={currentPaygroup}
        onChange={selectChangeHandler}
      >
        {paygroups.map((paygroup, i) => {
          const isSelected = paygroup.PAYGROUP === currentPaygroup;
          return (
            <MenuItem key={i} value={paygroup.PAYGROUP}>
              {/* {isSelected && <div className="line" />} */}
              <p
                className="item-text"
                style={{
                  color: isSelected ? "#00BFEF" : "#A9ACAF",
                  fontSize: 12,
                  margin: 0,
                }}
              >
                {`${dayjs(paygroup.PAY_START).format("MM/DD/YYYY")} - ${dayjs(
                  paygroup.PAY_END
                ).format("MM/DD/YYYY")}`}
              </p>
              {/* {isSelected && <div className="line" />} */}
            </MenuItem>
          );
        })}
      </Select>
    </StyledSelect>
  );
}

export default SelectPayGroup;

const StyledSelect = styled.div`
  display: flex;
  justify-content: center;
  border: 1px solid #d1d1d1;
  border-radius: 6px;
  padding: 8px 16px; 
  margin: 8px auto;

  .label-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  label {
    font-size: 10px;
    margin: 0 8px;
  }

  p {
    font-size: 12px;
    margin: 0px;
  }

  .MuiInput-underline::before {
    border: none;
  }

  .MuiInput-input {
    padding: 0px;
  }

   .MuiListItem-button > p {
    padding: 0px;
  }

  .MenuItem {
    display: flex;
    flex-direction: row;
  }

  item-text {
    width: 100%;
    font-size: 30px;
    margin: 0px;
    padding: 0;
  }

  .line {
    width: 51px;
    height: 1px;
    background: #00bfef;
  }

  .item-text {
    margin: 0;
  }



}
`;
