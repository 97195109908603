import React from "react";

import styled from "styled-components";
import { motion } from "framer-motion";

const DotLoader = () => {
  const animate = { opacity: 1, transform: "scale(1)" };
  const transition = {
    yoyo: Infinity,
    duration: 0.8,
  };
  return (
    <StyledLoading>
      <div className="dot-wrapper">
        <motion.div
          className="dot"
          animate={animate}
          transition={transition}
        ></motion.div>
        <motion.div
          className="dot"
          animate={animate}
          transition={{ ...transition, delay: 0.2 }}
        ></motion.div>
        <motion.div
          className="dot"
          animate={animate}
          transition={{ ...transition, delay: 0.4 }}
        ></motion.div>
      </div>
    </StyledLoading>
  );
};

const StyledLoading = styled.div`
  .dot-wrapper {
    display: flex;
    align-item: center
    margin-top: 8px;
    height: 8px;
  }

  .dot {
    margin: 8px;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background-color: #454545;
    opacity: 0.2;
    transform: scale(0.8)
  }
`;

export default DotLoader;
