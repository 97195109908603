import React from "react";

function NoData() {
  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ background: "#f4f4f4", padding: 16, borderRadius: 16 }}>
        <p style={{ fontSize: 20 }}>No Commission Data Found </p>
      </div>
    </div>
  );
}

export default NoData;
