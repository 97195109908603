import React from "react";
import { Chart as ChartJS, ArcElement } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import styled from "styled-components";

import InfoIcon from "../../assets/InfoIcon.svg";

import { formatDate, formatMoney } from "../../Utils/helpers";
import DotLoader from "./DotLoader";

function PayChart({
  chartData,
  payDate,
  grossPay,
  setShowLegend,
  setShowPayGlossary,
  loadingChart,
}) {
  ChartJS.register(ArcElement);
  const dataArr = chartData.map((el) => el.payAmount);
  const colors = chartData.map((el) => el.color);

  const data = {
    datasets: [
      {
        data: dataArr,
        backgroundColor: colors,
        borderColor: colors,
      },
    ],
  };

  return (
    <StyledPayChart>
      <Doughnut
        data={data}
        options={{
          responsive: true,
          maintainAspectRatio: true,
          cutout: "70%",
        }}
        style={{
          width: "100%",
        }}
      />
      <div className="pay-info-wrapper">
        {loadingChart ? (
          <div className="loader-wrapper">
            <p>Loading Pay Period</p>
            <DotLoader />
          </div>
        ) : (
          <>
            <button
              className="info-button"
              onClick={() => setShowPayGlossary(true)}
            >
              <img src={InfoIcon} alt="Icon of an 'i' to show info"></img>
            </button>
            <button className="pay-info" onClick={() => setShowLegend(true)}>
              <p>Total Gross Pay</p>
              <p style={{ fontSize: "calc(2rem + 2%)" }}>
                {formatMoney(grossPay)}
              </p>
              <p style={{ fontSize: "calc(0.5rem + 2%)", color: "#ADADAD" }}>
                To Be Paid On
              </p>
              <p>{formatDate(payDate)}</p>
            </button>
          </>
        )}
      </div>
    </StyledPayChart>
  );
}

export default PayChart;

const StyledPayChart = styled.div`
  max-width: 400px;
  aspect-ratio: 1;
  position: relative;
  margin: 0 auto;
  margin-top: 16px;

  .pay-info-wrapper {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
  }

  .pay-info {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    border-radius: 100%;
  }

  p {
    padding: 0;
    margin: 0;
    font-size: calc(0.75rem + 2%);
  }

  .info-button {
    position: absolute;
    top: 0;
    right: 0;
    margin: 16px;
  }

  .loader-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
