import React from "react";
import App from "./App";
import { BrowserRouter as Router, Route } from "react-router-dom";

const AppRouter = () => {
  return (
    <Router>
      <Route path="/:payid/:secret" component={App} />
    </Router>
  );
};

export default AppRouter;
