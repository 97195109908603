import React, { useEffect, useState } from "react";
import "./App.css";

import LoaderHolder from "./Components/V2/Loader/LoaderHolder";

import SelectPayGroup from "./Components/V2/SelectPayGroup";
import PayChart from "./Components/V2/PayChart";
import PayChartLegend from "./Components/V2/PayChartLegend";
import SitsAndSalesTable from "./Components/V2/SitsAndSalesTable";
import ComingSoon from "./Components/V2/ComingSoon";
import { buildChartData } from "./Utils/helpers";
import styled from "styled-components";
import NoData from "./Components/V2/NoData";

import { getPayData } from "./Utils/api";
import PayGlossary from "./Components/V2/PayGlossary";
import BonusChart from "./Components/V2/BonusChart";
import Maintenance from "./Components/V2/Maintenance";

const App = (props) => {
  const [invalid, setInvalid] = useState(false);
  const [loading, setLoading] = useState(true);
  const [apiBody, setAPIBody] = useState();
  const [prod, setProd] = useState();
  const [paygroupLocal, setPaygroupLocal] = useState("");
  const [showLegend, setShowLegend] = useState(false);
  const [showPayGlossary, setShowPayGlossary] = useState(false);
  const [loadingChart, setLoadingChart] = useState(false);

  useEffect(() => {
    setLoading(true);
    const {
      match: { params },
    } = props;

    if (params.secret !== "5bf2d58b9b7944cd9af0992ae43a07e4") {
      console.warn("Invalid Secret");
      setInvalid(true);
    } else {
      setLoading(true);
      getPayData(params)
        .then((res) => {
          setAPIBody(res.data);
          if (res.data.base[0].OFFICE.toLowerCase().includes("tx")) {
            setProd(true);
          } else {
            setProd(false);
          }
          setProd(true); //TODO: remove me
          setPaygroupLocal(res.data.payperiod.PAYGROUP);
          // setLoading(false);
        })
        .catch((e) => {
          // setLoading(false);
          setInvalid(true);
          console.warn(e);
        });
    }
  }, []);

  const selectChangeHandler = async (e) => {
    setLoadingChart(true);
    const {
      match: { params },
    } = props;
    setPaygroupLocal(e.target.value);
    const res = await getPayData(params, e.target.value);
    setAPIBody(res.data);
    setLoadingChart(false);
  };

  return (
    <StyledApp>
      {/* <Maintenance /> */}
      {showLegend && (
        <PayChartLegend
          chartData={buildChartData(apiBody)}
          setShowLegend={setShowLegend}
        />
      )}
      {showPayGlossary && (
        <PayGlossary setShowPayGlossary={setShowPayGlossary} />
      )}
      {!invalid && apiBody ? (
        prod ? (
          <div className="main">
            <PayChart
              chartData={buildChartData(apiBody)}
              grossPay={apiBody.base[0].TOTALPAY}
              payDate={apiBody.payperiod.PAY_DATE}
              setShowLegend={setShowLegend}
              setShowPayGlossary={setShowPayGlossary}
              loadingChart={loadingChart}
            />
            <p className="chart-text">
              Press the pie chart to view pay breakdown.
            </p>
            <SelectPayGroup
              paygroups={apiBody.paygroups_available}
              selectChangeHandler={selectChangeHandler}
              currentPaygroup={paygroupLocal}
            />
            <SitsAndSalesTable apiBody={apiBody} />
          </div>
        ) : (
          <ComingSoon />
        )
      ) : loading ? (
        loading && <LoaderHolder />
      ) : (
        <NoData />
      )}
    </StyledApp>
  );
};

export default App;

const StyledApp = styled.div`
  width: 100vw;
  display: flex;
  justify-content: center;

  .main {
    width: 95vw;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
  }

  .card {
    width: 100%;
    max-width: 600px;
    padding: 16px;
    margin: 10px;
    margin-top: 100px;
    margin-bottom: auto;
    background: white;
    border-radius: 15px;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 14;
    border-bottom: 1px solid #d1d1d1;

    padding-bottom: 8px;
    margin-bottom: 16px;

    p {
      color: #00bfef;
      margin: 0px;
      padding: 0px;
    }
  }
  .chart-text {
    font-size: 10px;
    text-align: center;
    color: #a9acaf;
    margin: 8px;
  }
`;
