import React from "react";

import XIcon from "../../assets/XIcon.svg";

import styled from "styled-components";

function PayGlossary({ setShowPayGlossary }) {
  return (
    <StyledGlossary>
      <div className="card">
        <div className="header">
          <p style={{ marginRight: "auto" }}>2022 Canvassing Comp Plan</p>
          <button onClick={() => setShowPayGlossary(false)}>
            <img src={XIcon} alt="X to quit"></img>
          </button>
        </div>
        <div className="section">
          <div className="two-column border">
            <p>Base Pay</p>
            <p>$500/Week</p>
          </div>
          <div className="two-column">
            <p>Demo Pay</p>
            <p>$50/Demo</p>
          </div>
          <div className="two-column">
            <p>Sale Pay</p>
            <p>$150/Sale</p>
          </div>
          <div className="two-column">
            <p>INS Pay</p>
            <p>$75/INS</p>
          </div>
          <div className="line" />
        </div>
        <p
          style={{
            textAlign: "left",
            width: "100%",
            color: "#00bfef",
            margin: "8px",
            marginLeft: 0,
          }}
        >
          Glossary
        </p>
        <div className="section">
          <p>INS - Internal No Show</p>
          <p>Base Pay</p>
          <p>INS Pay</p>
          <p>Sit Pay</p>
          <p>Sit Bonus</p>
          <p>Sales Pay</p>
          <p>Sales Bonus</p>
          <p>Overrides</p>
          <p>Referrals</p>
          <p>Spiffs</p>
          <p>Monthly Bonus</p>
        </div>
      </div>
    </StyledGlossary>
  );
}

export default PayGlossary;

const StyledGlossary = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  z-index: 100;
  height: 100%;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);

  button {
    padding: 20;
  }

  .section {
    width: 100%;
    p {
      font-size: 12px;
      text-align: left;
      margin: 0;
    }
  }

  .border {
    border: 1px solid #00bfef;
    border-radius: 20px;
    font-weight: bold;
    margin-left: -5px;
    padding-left: 5px;
  }

  .two-column {
    width: 100%;
    display: flex;
    flex-direction: row;

    p {
      padding: 5px 0px;
      flex: 1;
    }
  }

  .line {
    background: #d1d1d1;
    height: 1px;
    width: 100%;
    margin-top: 4px;
  }
`;
