import dayjs from "dayjs";

export const formatMoney = (val) =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(val);

export const formatDate = (rawDate, useShortYear = false) => {
  return useShortYear
    ? dayjs(rawDate.trim()).format("MM/DD/YY")
    : dayjs(rawDate.trim()).format("MM/DD/YYYY");
};

export const buildTableData = (data) => {
  return data.map((el) => {
    return {
      oppNum: el.OPPID,
      name: el.CUSTOMER || el.HOMEOWNER,
      date: el.APPTDATE || el.DATE,
      payAmount: el.amount,
    };
  });
};

export const buildChartData = (apiBody) => {
  const {
    BASEPAY,
    SALESPAY,
    SALESBONUS,
    SITPAY,
    SITBONUS,
    INSPAY,
    SPIFFS,
    OVERRIDES,
    MONTHLY,
  } = apiBody.base[0];
  return [
    {
      payAmount: SALESPAY,
      color: "#00BFEF",
      label: "Sales Pay",
    },
    {
      payAmount: BASEPAY,
      color: "#7FDDA9",
      label: "Base Pay",
    },
    {
      payAmount: SALESBONUS,
      color: "#F89642",
      label: "Sales Bonus",
    },
    {
      payAmount: SITPAY,
      color: "#9372D3",
      label: "Sit Pay",
    },
    {
      payAmount: SITBONUS,
      color: "#F8776A",
      label: "Sit Bonus",
    },
    {
      payAmount: INSPAY,
      color: "#336699",
      label: "INS Bonus",
    },
    {
      payAmount: OVERRIDES,
      color: "#CBBA00",
      label: "Overrides",
    },
    {
      payAmount: MONTHLY,
      color: "#F08EE1",
      label: "Monthly",
    },
    {
      payAmount: SPIFFS,
      color: "#BE5151",
      label: "Spiffs",
    },
  ];
};
