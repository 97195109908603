import React from "react";
import Loader from "./Loader";
import { StyledLoadPage } from "./styles";

const LoaderHolder = () => {
  return (
    <StyledLoadPage>
      <Loader />
    </StyledLoadPage>
  );
};

export default LoaderHolder;
